
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { getEncounter } from "@/api/encounter.api";
import { Encounter } from "@/modules/encounter/encounter.model";
import EncounterComponent from "@/modules/encounter/EncounterComponentV2.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export interface Data {
  encounter?: Encounter;
  isLoaded: boolean;
  counter: number;
}

export default defineComponent({
  name: "EncountersPage",
  props: ["encounterId"],
  components: { EncounterComponent },
  beforeRouteLeave(to, from, next) {
    const component = this.encounterComp as any;
    if (
      (component.data.isDirty ||
        component.facilityEncounter?.data.isDirty ||
        component.professionalEncounter?.data.isDirty) &&
      !component.data.selectedFacilityIncomplete
    ) {
      let text = "Are you sure you want to leave without saving changes?";
      Swal.fire({
        title: text,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        } else if (result.isDismissed) {
          next(false);
        }
      });
    } else {
      next();
    }
  },
  setup(props) {
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      encounter: undefined,
      isLoaded: false,
      counter: 0,
    });

    const encounterComp = ref(null);

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Encounter Page");

      if (props.encounterId) {
        data.encounter = await getEncounter(props.encounterId);
      }

      data.isLoaded = true;
    });

    function handleNewEncounter() {
      data.encounter = undefined;
      data.counter += 1;
    }

    function reload() {
      data.counter += 1;
    }

    return {
      handleNewEncounter,
      organizationId,
      encounterComp,
      reload,
      data,
    };
  },
});
