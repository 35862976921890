
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import PaginationUi from "@/components/ABilling/Pagination.vue";
import useFilters from "../common/useFilters";
import { download } from "@/api/statement.api";
import { getAudit, getPanel } from "@/api/encounter.api";
import { getDictionary, getTypeOfBillCode } from "@/api/dictionaries.api";
import { getReferringProviders } from "@/api/referringProvider.api";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface RevenueCode {
  code: string;
  description: string;
  chargePerUnit: number;
  rate?: number;
}
export interface Data {
  isLoaded: boolean;
  items: [];
  keyword: string;
  pagination: Pagination;
  orderBy: string;
  isDecr: boolean;
  panel: any;
  IsAdvancedOrderBy: boolean;
  showInactive: boolean;
  dict: {
    professionalbillingProvider: any[];
    referringProviders: any[];
    renderingProviders: any[];
    typeOfFacility: any[];
    typeOfCare: any[];
    frequency: any[];
    admissionType: any[];
    admissionSource: any[];
    dischargeStatus: any[];
    boolList: { code?: boolean | null; name: string }[];
  };
}
export default defineComponent({
  name: "GuarantorList",
  props: ["encounter"],
  components: { PaginationUi },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const { orderList, putFiltersToUrl, getFiltersFromUrl, getFilter } =
      useFilters();
    orderList.value = [
      {
        name: "User",
        key: "user",
        isFilter: false,
        keyword: null,
      },
      {
        name: "Date",
        key: "dateTime",
        isFilter: false,
        keyword: null,
      },
      {
        name: "Action",
        key: "type",
        isFilter: false,
        keyword: null,
      },
      {
        name: "Description",
        key: "description",
        isFilter: false,
        keyword: null,
      },
    ];

    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      isLoaded: false,
      items: [],
      keyword: "",
      orderBy: "statementId",
      IsAdvancedOrderBy: true,
      isDecr: true,
      showInactive: false,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 10,
      },
      panel: {},
      dict: {
        professionalbillingProvider: [],
        referringProviders: [],
        renderingProviders: [],
        typeOfFacility: [],
        typeOfCare: [],
        frequency: [],
        admissionType: [],
        admissionSource: [],
        dischargeStatus: [],
        boolList: [
          { code: true, name: "Yes" },
          { code: false, name: "No" },
        ],
      },
    });

    onMounted(async () => {
      data.panel = await getPanel();
      await getDict();
      organizationId.value = getOrganization();
      await getAll();
      data.isLoaded = true;
    });

    async function getDict() {
      data.panel = await getPanel();

      data.dict.professionalbillingProvider = data.panel.providers;
      data.dict.renderingProviders = data.panel.providers;
      data.dict.referringProviders = await getReferringProviders();

      data.panel.billingProviders = data.panel.facilities;

      const typeOfBillCodes = await getTypeOfBillCode();

      data.dict.typeOfFacility = typeOfBillCodes.filter((item) => {
        return item.level == 1;
      });
      data.dict.typeOfCare = typeOfBillCodes.filter((item) => {
        return item.level == 2;
      });
      data.dict.frequency = typeOfBillCodes.filter((item) => {
        return item.level == 3;
      });
      data.dict.admissionType = await getDictionary(7, "");
      data.dict.admissionSource = await getDictionary(8, "");
      data.dict.dischargeStatus = await getDictionary(9, "");
    }

    function getFacilityValue(fieldName, value) {
      if (fieldName == "Service Facility Location") {
        return data.panel.facilities.find((c) => {
          return c.id == value;
        })?.name;
      }
      if (fieldName == "Attending Provider") {
        return data.panel.providers.find((c) => {
          return c.id == value;
        })?.fullName;
      }
      if (fieldName == "Billing Provider") {
        return data.panel.billingProviders.find((c) => {
          return c.id == value;
        })?.name;
      }
      if (fieldName == "Admission Type") {
        return data.dict.admissionType.find((c) => {
          return c.code == value;
        })?.description;
      }
      if (fieldName == "Admission Source") {
        return data.dict.admissionSource.find((c) => {
          return c.code == value;
        })?.description;
      }
      if (fieldName == "Discharge Status") {
        return data.dict.dischargeStatus.find((c) => {
          return c.code == value;
        })?.description;
      }
      if (fieldName == "Bill Type") {
        return data.dict.frequency.find((c) => {
          return c.code == value;
        })?.fullDescription;
      }
      if (fieldName == "Bill Sub Type") {
        return data.dict.typeOfCare.find((c) => {
          return c.code == value;
        })?.fullDescription;
      }
      if (fieldName == "Bill Sub Type 2") {
        return data.dict.typeOfFacility.find((c) => {
          return c.code == value;
        })?.fullDescription;
      }
      return value;
    }

    function getProfessionalValue(fieldName, value) {
      if (fieldName == "Service Facility Location") {
        return data.panel.facilities.find((c) => {
          return c.id == value;
        })?.name;
      }
      if (fieldName == "Billing Provider") {
        return data.dict.professionalbillingProvider.find((c) => {
          return c.id == value;
        })?.name;
      }
      if (fieldName == "Rendering Provider") {
        return data.dict.renderingProviders.find((c) => {
          return c.id == value;
        })?.name;
      }
      if (fieldName == "Referring Provider") {
        return data.dict.referringProviders.find((c) => {
          return c.id == value;
        })?.fullName;
      }
      return value;
    }

    function getUpdateServiceCode(entityChange) {
      const service = props.encounter.services.find((c) => {
        return c.id == entityChange.id;
      });
      if (!service) return "(Deleted)";
      if (service.revenueCode) return service.revenueCode;
      return service.procCode;
    }

    function getValue(fieldName, value) {
      if (props.encounter.encounterType == 0)
        return getFacilityValue(fieldName, value);
      return getProfessionalValue(fieldName, value);
    }

    const procCode = ["Procedure Code", "Revenue Code"];

    function getProcCodeTo(entityChange) {
      return entityChange.changes.find((c) => {
        return c.to && procCode.includes(c.fieldName);
      })?.to;
    }

    function getProcCodeFrom(entityChange) {
      return entityChange.changes.find((c) => {
        return c.from && procCode.includes(c.fieldName);
      }).from;
    }

    async function search() {
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function getAll() {
      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }

      if (props.encounter.id) {
        let request = {
          keyword: data.keyword,
          pageNumber: data.pagination.currentPage,
          pageSize: data.pagination.pageSize,
          orderBy: orderBy,
          advancedOrderBy: advancedOrderBy,
          advancedSearch: {
            fields: orderList.value.map((item) => {
              return item.key;
            }),
            keyword: data.keyword,
          },
          EncounterId: props.encounter.id,
        };

        const res = await getAudit(request);
        data.items = res.data;
        data.pagination.currentPage = res.currentPage;
        data.pagination.totalPages = res.totalPages;
        data.pagination.totalCount = res.totalCount;
        data.pagination.pageSize = res.pageSize;
      }
    }

    async function clearSearch(header) {
      header.keyword = null;
      await search();
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function getPDF(statement) {
      await download(statement);
    }

    return {
      organizationId,
      clearSearch,
      data,
      getProcCodeTo,
      getUpdateServiceCode,
      getProcCodeFrom,
      orderList,
      search,
      pageChanged,
      pageSizeChanged,
      getValue,
      getPDF,
    };
  },
});
